<template>
	<v-navigation-drawer v-model="drawer" :mini-variant="mini" permanent app right style="z-index: 101" width="350px">
		<template v-if="!mini">
			<v-card flat>
				<v-card-text>
					<slot name="prepend"></slot>
					<v-text-field label="Search" v-model="search"></v-text-field>
				</v-card-text>
			</v-card>
			<v-divider></v-divider>
			<v-list dense v-model="active">
				<v-list-item v-for="(item, i) in filteredItems" :key="i" :id="item.name" @click="scrollTo(item.id)">
					<v-list-item-icon>
						<v-icon>mdi-chevron-right</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							{{ item.title }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<slot name="append"></slot>
			</v-list>
		</template>
		<template v-slot:append>
			<v-divider class="mt-1 mb-3"></v-divider>
			<v-list dense class="pl-1">
				<nav-item @click="mini = !mini" :text="$t('buttons.collapse')" :icon="mini ? 'mdi-chevron-left' : 'mdi-chevron-right'"></nav-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>

<style lang="less" scoped>
.v-list-item__title {
	overflow: auto;
	white-space: normal;
}
</style>
<script>
import NavItem from "@c/navigation/Item";

export default {
	name: "ContentContetns",
	props: {
		value: { type: Array, default: () => [] },
	},
	components: {
		NavItem,
	},
	data: () => {
		return {
			drawer: true,
			mini: false,
			active: 1,
			search: "",
		};
	},
	computed: {
		items() {
			const self = this;
			let items = self.value.filter((id) => self.$store.state.content.data[id]).map((id) => self.$store.state.content.data[id]);
			return items;
		},
		filteredItems() {
			const self = this;
			return self.items.filter((item) => {
				return item.title.toLowerCase().includes(this.search.toLowerCase());
			});
		},
	},
	methods: {
		scrollTo(id) {
			let pos = document.getElementById(id).offsetTop - 20;
			window.scrollTo({
				top: pos,
				behavior: "smooth",
			});
		},
	},
};
</script>
